import api from './api'

export async function get(query) {
    return api
        .get(`/search/${query}`)
        .then((res) => res.data)
        .catch((err) => console.error(err))
}

export default { get }
