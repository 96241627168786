import axios, { ResponseType } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

function forceLogout() {
  localStorage.clear()
  window.location.reload()
}

const refreshAuthLogic = (failedRequest) => {
  const expiredToken = localStorage.getItem('access_token')
  return axios
    .post(`${process.env.REACT_APP_BACKOFFICE_BE_BASE_URL}/refresh`, null, {
      params: { token: expiredToken },
    })
    .then((tokenRefreshResponse) => {
      localStorage.setItem('access_token', tokenRefreshResponse.data.access_token)
      failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access_token}`
      return Promise.resolve()
    })
    .catch((e) => {
      console.error(e)
      forceLogout()
    })
}
const api = (
  baseUrl,
  setAuthorizationHeader = true,
  responseType?: ResponseType,
  axiosInstance = axios.create({
    baseURL: baseUrl,
  }),
) => {
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
  if (responseType) {
    axiosInstance.defaults.responseType = responseType
  }
  if (setAuthorizationHeader) {
    axiosInstance.interceptors.request.use(
      (reqConfig) => {
        reqConfig.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`
        return reqConfig
      },
      (err) => Promise.reject(err),
    )
    createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic)
  }
  return axiosInstance
}

export default api(process.env.REACT_APP_BACKOFFICE_BE_BASE_URL)

export const adminUsersApi = api(process.env.REACT_APP_WEBAPP_BE_BASE_URL)

export const serviceProposalApi = api(process.env.REACT_APP_PROPOSAL_SERVICE_BASE_URL)

export const servicePromotionApi = api(process.env.REACT_APP_PROMOTION_SERVICE_BASE_URL)

export const serviceOrdersApi = api(process.env.REACT_APP_ORDERS_SERVICE_BASE_URL)

export const serviceProductPriceApi = api(process.env.REACT_APP_PRODUCT_PRICE_SERVICE_BASE_URL)

export const serviceBacklogApi = api(process.env.REACT_APP_BACKLOG_SERVICE_BASE_URL)

export const serviceAssetApi = api(process.env.REACT_APP_ASSET_SERVICE_BASE_URL)

export const serviceProductApi = api(process.env.REACT_APP_PRODUCT_SERVICE_BASE_URL)

export const serviceDeliveryApi = api(process.env.REACT_APP_DELIVERY_SERVICE_BASE_URL)

export const logisticPartnerApi = api(process.env.REACT_APP_LOGISTIC_PARTNER_SERVICE_BASE_URL)
export const logisticCostsApi = api(process.env.REACT_APP_LOGISTIC_COSTS_SERVICE_BASE_URL)

export const warehouseServiceApi = api(process.env.REACT_APP_WAREHOUSE_SERVICE_BASE_URL)

export const financeCustomersApi = api(process.env.REACT_APP_FINANCE_SERVICE_BASE_URL)

export const gotenbergPDFApi = api(
  process.env.REACT_APP_GOTENBERG_PDF_SERVICE_BASE_URL,
  false,
  'blob',
)
