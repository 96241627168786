import api from './api'

export const createCampaignMapping = async (data) => {
  return api.post('/marketing-campaign-mappings', data)
}

export const showCampaignMapping = async (id) => {
  return api.get(`/marketing-campaign-mappings/${id}`).then((res) => res.data)
}

export const updateCampaignMapping = async (id, data) => {
  return api.patch(`/marketing-campaign-mappings/${id}`, data).then((res) => res.data)
}

export const deleteCampaignMapping = async (id) => {
  return api.delete(`/marketing-campaign-mappings/${id}`)
}

export default {
  createCampaignMapping,
  showCampaignMapping,
  updateCampaignMapping,
  deleteCampaignMapping,
}
