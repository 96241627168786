import api from './api'

export const updateLead = async (id, data) => {
  return api.patch(`/leads/${id}`, data).then((res) => res.data)
}

export const gdprDataRemovalLead = async (id) => {
  return api.patch(`/leads/${id}/gdprdataremoval`).then((res) => res.data)
}

export const createLead = async (data) => {
  return api.post('/leads', data)
}

export const setup = async () => {
  return api.get('leads/setup').then((res) => res.data)
}

export const getLead = async (id) => {
  return api.get(`/leads/${id}`).then((res) => res.data)
}

export default { updateLead, gdprDataRemovalLead, createLead, setup, getLead }
