import { logisticCostsApi, serviceProposalApi } from './api'

export const massUpload = async (data) => {
  const url = '/bulk/products'

  return logisticCostsApi.post(url, data)
}

export const calculate = async (data) => {
  const url = '/logistics/calculate/cost'

  return serviceProposalApi.post(url, data)
}

export default { massUpload, calculate }
