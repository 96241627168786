import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import HeaderNavigation from '../components/custom/menu/HeaderNavigation'
import { Alert, Breadcrumb, Layout } from 'antd'
import { Link } from 'react-router-dom'
import useBreadcrumb from '../../hooks/useBreadcrumb'
import { Header } from 'antd/lib/layout/layout'

const { Content } = Layout

export default function MainLayout({ children }) {
  const env = process.env.REACT_APP_NODE_ENV || 'production'
  const { breadcrumbList } = useBreadcrumb()

  return (
    <Layout>
      <Header>
        {env !== 'production' && (
          <Alert
            style={{ textAlign: 'center' }}
            message={`You are in "${env}" environment. Your changes don't apply to Production.`}
            type="error"
          />
        )}
        <HeaderNavigation />
      </Header>
      <Content>
        {breadcrumbList.length > 0 && (
          <Breadcrumb>
            {breadcrumbList.map((breadcrumb: any) => {
              return (
                <Breadcrumb.Item key={breadcrumb.id}>
                  {!breadcrumb.path ? (
                    breadcrumb.label
                  ) : (
                    <Link to={breadcrumb.path}>{breadcrumb.label}</Link>
                  )}
                </Breadcrumb.Item>
              )
            })}
          </Breadcrumb>
        )}
        <div className="site-layout-content">
          {children}
          <ToastContainer />
        </div>
      </Content>
    </Layout>
  )
}
