import { useCallback, useContext, useEffect, useState } from 'react'
import useMenu from './useMenu'
import { useLocation } from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { VerticalContext } from '../context/VerticalContext'
import useTranslation from './translation'

export default function useBreadcrumb() {
  const { T } = useTranslation()
  const { menu } = useMenu()
  const location = useLocation()
  const { verticals } = useContext(VerticalContext)
  const [menuData, setMenuData] = useState<any>(menu)
  const [breadcrumbList, setBreadcrumbList] = useState([])
  const [openList, setOpenList] = useState<any>([])

  const checkParentDirectory = (nodeName) => {
    if (menuData[nodeName].isRoot) {
      return
    }
    Object.keys(menuData).forEach((newNodeName) => {
      if (menuData[newNodeName].children.length > 0) {
        if (menuData[newNodeName].children.includes(nodeName)) {
          menuData[newNodeName].isOpen = true
          checkParentDirectory(newNodeName)
        }
      }
    })
  }

  const createBreadcrumbList = useCallback(
    (data, node) => {
      data.push(node)
      const child = openList.find(
        (s) =>
          s.id ===
          node.children.find((n) => {
            return openList.find((sd) => sd.id === n)
          }),
      )
      if (child && child.children.length) {
        createBreadcrumbList(data, child)
      } else {
        data.push(child)
      }
      return data
    },
    [openList],
  )

  useDeepCompareEffect(() => {
    if (verticals.count() < 1) {
      return
    }
    verticals.each((vertical) => {
      const verticalMenuID = `${vertical.slug}-${vertical.id}`
      if (menuData.products.children.includes(verticalMenuID)) {
        return
      }
      menuData.products.children.push(verticalMenuID)
      menuData[verticalMenuID] = {
        id: verticalMenuID,
        label: T(vertical.name),
        path: `/products/${vertical.slug}/`,
        children: [],
      }
      vertical.categories.forEach((category) => {
        const categoryMenuID = `${category.slug}-${category.id}-${vertical.slug}-${vertical.id}`
        menuData[verticalMenuID].children.push(categoryMenuID)
        menuData[categoryMenuID] = {
          id: categoryMenuID,
          label: T(category.name),
          path: `/products/${vertical.slug}/${category.slug}/list`,
          children: [],
        }
      })
    })

    Object.keys(menuData).forEach((nodeName) => {
      menuData[nodeName].isOpen = false
      let openNode = false

      if (menuData[nodeName].path != null) {
        if (
          menuData[nodeName].path.replace(/^\/|\/$/g, '') ===
          location.pathname.replace(/^\/|\/$/g, '')
        ) {
          openNode = true
        }
      }
      if (openNode) {
        menuData[nodeName].isOpen = true
        checkParentDirectory(nodeName)
      }
    })
    setMenuData({ ...menuData })
  }, [verticals, menuData, location])

  useEffect(() => {
    setOpenList([...Object.values(menuData).filter((m: any) => m.isOpen === true)])
  }, [menuData, location.pathname])

  useEffect(() => {
    const root = openList.find((ol: any) => ol.isRoot === true)
    if (root === undefined) {
      return
    }
    setBreadcrumbList(createBreadcrumbList([], root).filter((b) => b))
  }, [openList, createBreadcrumbList])

  return { breadcrumbList }
}
