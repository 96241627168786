import algoliasearch from 'algoliasearch'

const appID = process.env.REACT_APP_ALGOLIA_APP_ID || ''
const appKey = process.env.REACT_APP_ALGOLIA_API_KEY || ''
const prefix = process.env.REACT_APP_ALGOLIA_API_PREFIX || ''
const platformPrefix = process.env.REACT_APP_ALGOLIA_API_PREFIX_PLATFORM || ''

const searchClient = (algoliaKey?: string) => {
  if (!algoliaKey) {
    algoliaKey = appKey
  }
  return algoliasearch(appID, algoliaKey)
}

const buildIndexName = (id: string) => {
  return `${prefix.replace(/_+$/, '')}_${id}`
}

const buildPlatformIndexName = (id: string) => {
  return `${platformPrefix}_${id}`
}

export default { searchClient, buildIndexName, buildPlatformIndexName }
