import collect from 'collect.js'
import api from './api'

export function listVerticals() {
  return api
    .get('/verticals')
    .then((response) => collect(response.data))
    .catch((error) => {
      console.error(error)
      return collect({})
    })
}

export default { listVerticals }
