import api,{serviceAssetApi} from './api'

export const createReturn = async (data) => {
  return api.post('/returns', data)
}

export const createAssetsReturn = (data) => {
  return serviceAssetApi.post('/returns', data).then(res => res.data).catch(error => {throw error})
}

export const updateAssetsReturn = (returnId,data) => {
  return serviceAssetApi.patch(`/returns/${returnId}/return-items`, data).then(res => res.data).catch(error => {throw error})
}

export const getReturn = async (id) => {
  return api.get(`/returns/${id}`).then((res) => res.data)
}

export const updateReturn = async (id, data) => {
  return api.patch(`/returns/${id}`, data).then((res) => res.data)
}

export const deleteReturnj5 = async (id) => {
  return api.delete(`/returns/${id}`)
}

export const deleteAssetsReturn = async (returnId) => {
  return serviceAssetApi.delete(`/returns/${returnId}`).then(res => res.data).catch(error => {throw error})
}

const deleteReturn = process.env.REACT_APP_NODE_ENV === 'production' ? deleteReturnj5 : deleteAssetsReturn

export default { createReturn, getReturn, updateReturn, deleteReturn, deleteAssetsReturn }
