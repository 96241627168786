import api from './api'

export async function getProduct(furnitureID) {
  return api.get(`/furniture/${furnitureID}`).then((res) => res.data)
}

export const createFurniture = (data) => {
  return api.post('/furniture', data).then((res) => res.data)
}

export const updateProduct = (id, data) => {
  return api.patch(`/furniture/${id}`, data).then((res) => res.data)
}

export async function getRelatedProducts(furnitureID) {
  return api.get(`furniture/${furnitureID}/relation`).then((res) => res.data)
}

export default { getProduct, createFurniture, updateProduct, getRelatedProducts }
