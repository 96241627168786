export type J5Warehouse = {
  id: number | string
  addressId: number
  address_line_1: string
  address_line_2: string
  name: string
  street: string
  number: string
  zipcode: string
  city: string
  country: string
}

export interface Warehouse {
  id: string
  name: string
  addressId: number
  addressLine1: string
  addressLine2: string
  street: string
  number: string
  zipcode: string
  city: string
  country: string
}

export class WarehouseModel implements Warehouse {
  id: string
  name: string
  addressId: number
  addressLine1: string
  addressLine2: string
  street: string
  number: string
  zipcode: string
  city: string
  country: string
  private constructor(input: J5Warehouse | Warehouse) {
    this.id = input.id.toString()
    this.name = input.name
    this.addressId = input.addressId
    this.addressLine1 = 'addressLine1' in input ? input.addressLine1 : input.address_line_1
    this.addressLine2 = 'addressLine2' in input ? input.addressLine2 : input.address_line_2
    this.zipcode = input.zipcode
    this.city = input.city
    this.country = input.country
    this.number = input.number
    this.street = input.street
  }

  static create(input: J5Warehouse | Warehouse): WarehouseModel {
    return new WarehouseModel(input)
  }

  public formatAddress() {
    let formattedAddress = ''
    formattedAddress += this.addressLine1 ? this.addressLine1 + ', ' : ''
    formattedAddress += this.city ? this.city + ', ' : ''
    formattedAddress += this.zipcode ? this.zipcode + ', ' : ''
    formattedAddress += this.country ? this.country : ''

    return formattedAddress
  }

  public assembleWarehouseLabel(): string {
    return (
      this.name +
      ' ' +
      this.street +
      ' ' +
      this.number +
      ', ' +
      this.zipcode +
      ' ' +
      this.city +
      ' (' +
      this.id +
      ')'
    )
  }
}
