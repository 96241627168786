import React from 'react'
import { useLocation, BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import loadable from '@loadable/component'
import MainLayout from './theme/layout/MainLayout'
import { auth } from './services'
import { UserContextProvider } from './context/user'
import { VerticalContextProvider } from './context/VerticalContext'
import { AlgoliaContextProvider } from './context/AlgoliaContext'
import { LanguageContextProvider } from './context/language'
import routes from './routes'
import ScrollToTop from './hooks/ScrollToTop'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
;(function () {
  const _error = console.error
  const _warning = console.warning

  console.error = function (errMessage) {
    Sentry.captureException(errMessage)
    _error.apply(console, arguments)
  }

  console.warning = function (warnMessage) {
    Sentry.captureException(warnMessage)
    _warning.apply(console, arguments)
  }
})()

if (
  process.env.REACT_APP_SENTRY_ENVIRONMENT === 'production' ||
  process.env.REACT_APP_SENTRY_ENVIRONMENT === 'staging'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: 0,
    logLevel: process.env.REACT_APP_SENTRY_LOG_LEVEL,
    levels: ['warn', 'error'],
  })
}

const Login = loadable(() => import('./pages/login/Login'))
function App() {
  return (
    <React.Fragment>
      <Sentry.ErrorBoundary fallback={'An error has occurred'}>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/login" exact component={Login} />
            <LanguageContextProvider>
              <QueryClientProvider client={new QueryClient()}>
              <ReactQueryDevtools initialIsOpen={false} />
              <UserContextProvider>
                <VerticalContextProvider>
                  <AlgoliaContextProvider>
                    <MainLayout>
                      {routes.map((route) => {
                        if (route.logistic_process_enable === false) return

                        return (
                          <PrivateRoute
                            path={route.path}
                            key={route.path}
                            exact={route.exact}
                            component={route.component}
                          />
                        )
                      })}
                    </MainLayout>
                  </AlgoliaContextProvider>
                </VerticalContextProvider>
              </UserContextProvider>
              </QueryClientProvider>
            </LanguageContextProvider>
          </Switch>
        </Router>
      </Sentry.ErrorBoundary>
    </React.Fragment>
  )
}

function PrivateRoute({component: Component, ...rest}) {
  const location = useLocation()
  const hashLocation = btoa(location.pathname + location.search)
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{pathname: `/login?redirect=${hashLocation}`, state: {from: props.location}}}
          />
        )
      }
    />
  )
}

export default App
