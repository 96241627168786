import React, { useEffect, useState } from 'react'
import { auth } from '../services'
import * as Sentry from '@sentry/react'

export const UserContext = React.createContext(null)

export function UserContextProvider({ children }) {
  const [user, setUser] = useState({ details: { avatar: '' } })
  useEffect(() => {
    const currentUser = auth.currentUser()
    Sentry.setUser(currentUser.details)
    setUser(currentUser)
  }, [])
  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
}
export default { UserContext, UserContextProvider }
