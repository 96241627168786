import loadable from '@loadable/component'
import featureTogglesData from './featureToggles/featureTogglesData'
import { routes as financeRoutes } from './pages/finance'

const routes = [
  {
    path: '/',
    component: loadable(() => import('./pages/dashboard/Dashboard')),
    exact: true,
  },
  // Products
  {
    path: '/products/:verticalName?/',
    component: loadable(() => import('./pages/product/ProductsNavigation')),
    exact: true,
  },
  {
    path: '/products/:verticalName/:categoryName/list',
    component: loadable(() => import('./pages/product/ProductListing')),
    exact: true,
  },
  {
    path: '/products/:verticalName/:categoryName/create',
    component: loadable(() => import('./pages/product/ProductCreateFactory')),
    exact: true,
  },
  {
    path: '/products/:lendisID/view',
    component: loadable(() => import('./pages/product/ProductDetails')),
    exact: true,
  },
  {
    path: '/products/:lendisID/relation',
    component: loadable(() => import('./pages/product/ProductRelation')),
    exact: true,
  },
  {
    path: '/products/:lendisID/edit',
    component: loadable(() => import('./pages/product/ProductEdit')),
    exact: true,
  },
  // Colors
  {
    path: '/colors/',
    component: loadable(() => import('./pages/color/ColorListing')),
    exact: true,
  },
  {
    path: '/colors/create',
    component: loadable(() => import('./pages/color/ColorCreate')),
    exact: true,
  },
  {
    path: '/colors/view/:colorID',
    component: loadable(() => import('./pages/color/ColorEdit')),
    exact: true,
  },
  // Suppliers
  {
    path: '/suppliers/',
    component: loadable(() => import('./pages/supplier/SupplierListing')),
    exact: true,
  },
  {
    path: '/suppliers/create',
    component: loadable(() => import('./pages/supplier/SupplierCreate')),
    exact: true,
  },
  {
    path: '/suppliers/view/:supplierID',
    component: loadable(() => import('./pages/supplier/SupplierEdit')),
    exact: true,
  },
  // Manufacturers
  {
    path: '/manufacturers/',
    component: loadable(() => import('./pages/manufacturer/ManufacturerListing')),
    exact: true,
  },
  {
    path: '/manufacturers/create',
    component: loadable(() => import('./pages/manufacturer/ManufacturerCreate')),
    exact: true,
  },
  {
    path: '/manufacturers/view/:manufacturerID',
    component: loadable(() => import('./pages/manufacturer/ManufacturerEdit')),
    exact: true,
  },
  // Buyers
  {
    path: '/buyers/',
    component: loadable(() => import('./pages/buyer/BuyerListing')),
    exact: true,
  },
  {
    path: '/buyers/create',
    component: loadable(() => import('./pages/buyer/BuyerCreate')),
    exact: true,
  },
  {
    path: '/buyers/view/:buyerID',
    component: loadable(() => import('./pages/buyer/BuyerEdit')),
    exact: true,
  },
  // Leads
  {
    path: '/marketing/leads/',
    component: loadable(() => import('./pages/lead/LeadListing')),
    exact: true,
  },
  {
    path: '/marketing/leads/create',
    component: loadable(() => import('./pages/lead/LeadCreate')),
    exact: true,
  },
  {
    path: '/marketing/leads/view/:leadID',
    component: loadable(() => import('./pages/lead/LeadView')),
    exact: true,
  },
  {
    path: '/marketing/leads/edit/:leadID',
    component: loadable(() => import('./pages/lead/LeadEdit')),
    exact: true,
  },
  // campaign-mapping
  {
    path: '/marketing/campaign-mapping/',
    component: loadable(() => import('./pages/campaignMapping/CampaignMappingList')),
    exact: true,
  },
  {
    path: '/marketing/campaign-mapping/create',
    component: loadable(() => import('./pages/campaignMapping/CampaignMappingCreate')),
    exact: true,
  },
  {
    path: '/marketing/campaign-mapping/:CampaignMappingID/view',
    component: loadable(() => import('./pages/campaignMapping/CampaignMappingView')),
    exact: true,
  },
  {
    path: '/marketing/campaign-mapping/:CampaignMappingID/edit',
    component: loadable(() => import('./pages/campaignMapping/CampaignMappingEdit')),
    exact: true,
  },
  // Users
  {
    path: '/admin/users',
    component: loadable(() => import('./pages/user/UserListing')),
    exact: true,
  },
  // logistic cost
  {
    path: '/logistic-cost',
    component: loadable(() => import('./pages/logisticCost/LogisticCost')),
    exact: true,
  },
  // Finance
  {
    path: '/finance',
    component: loadable(() => import('./pages/finance/Finance')),
    exact: true,
  },
  {
    path: '/finance-order',
    component: loadable(() => import('./pages/finance/FinanceOrder')),
    exact: true,
  },
  ...financeRoutes,
  // Customers
  {
    path: '/customers',
    component: loadable(() => import('./pages/customer/CustomerListing')),
    exact: true,
  },
  {
    path: '/customers/create',
    component: loadable(() => import('./pages/customer/CustomerCreate')),
    exact: true,
  },
  {
    path: '/customers/view/:customerID',
    component: loadable(() => import('./pages/customer/CustomerView')),
    exact: true,
  },
  {
    path: '/customers/view/:customerID/los',
    component: loadable(() => import('./pages/customer/LosUserListing')),
    exact: true,
  },
  // Order
  {
    path: '/orders',
    component: loadable(() => import('./pages/order/OrderList')),
    exact: true,
  },
  {
    path: '/orders/:orderID',
    component: loadable(() => import('./pages/order/OrderDetail')),
    exact: true,
  },
  {
    path: '/orders/:id/history',
    component: loadable(() => import('./pages/order/OrderHistory')),
    exact: true,
  },
  //Backlog
  {
    path: '/backlog',
    component: loadable(() => import('./pages/backlog/BacklogList')),
    exact: true,
    logistic_process_enable: featureTogglesData.logistic_process,
  },
  // Proposal
  {
    path: '/proposals',
    component: loadable(() => import('./pages/proposal/ProposalList')),
    exact: true,
  },
  {
    path: '/proposals/:proposalID/edit',
    component: loadable(() => import('./pages/proposal/wizard/ProposalWizard')),
    exact: true,
  },
  {
    path: '/proposals/:proposalID',
    component: loadable(() => import('./pages/proposal/ProposalDetail')),
    exact: true,
  },
  // Warehouse
  {
    path: '/warehouses',
    component: loadable(() => import('./pages/warehouse/WarehouseListing')),
    exact: true,
  },
  {
    path: '/warehouses/edit/:warehouseID',
    component: loadable(() => import('./pages/warehouse/WarehouseEdit')),
    exact: true,
  },
  {
    path: '/warehouses/create',
    component: loadable(() => import('./pages/warehouse/WarehouseCreate')),
    exact: true,
  },
  // Inventory
  {
    path: '/inventories/',
    component: loadable(() => import('./pages/inventory/InventoryList')),
    exact: true,
    logistic_process_enable: !featureTogglesData.logistic_process,
  },
  // Assets
  {
    path: '/assets/',
    component: loadable(() => import('./pages/assets/AssetList')),
    exact: true,
    logistic_process_enable: featureTogglesData.logistic_process,
  },
  {
    path: '/assets/returns',
    component: loadable(() => import('./pages/assets/ReturnsList')),
    exact: true,
    logistic_process_enable: featureTogglesData.logistic_process,
  },
  {
    path: '/assets/returns/wizard',
    component: loadable(() => import('./pages/assets/ReturnsWizard')),
    exact: true,
    logistic_process_enable: featureTogglesData.logistic_process,
  },

  {
    path: '/assets/transfers',
    component: loadable(() => import('./pages/inventory/transfer/TransferListing')),
    exact: true,
    logistic_process_enable: featureTogglesData.logistic_process,
  },
  {
    path: '/assets/transfers/wizard',
    component: loadable(() => import('./pages/inventory/transfer/TransferWizard')),
    exact: true,
    logistic_process_enable: featureTogglesData.logistic_process,
  },
  {
    path: '/assets/transfers/view/:transferId',
    component: loadable(() => import('./pages/assets/TransferDetails')),
    exact: true,
    logistic_process_enable: featureTogglesData.logistic_process,
  },
  {
    path: '/assets/returns/view/:returnID',
    component: loadable(() => import('./pages/assets/ReturnDetails')),
    exact: true,
    logistic_process_enable: featureTogglesData.logistic_process,
  },
  {
    path: '/assets/edit/:assetId',
    component: loadable(() => import('./pages/assets/AssetEdit')),
    exact: true,
    logistic_process_enable: featureTogglesData.logistic_process,
  },
  // Edit
  {
    path: '/inventories/edit/:lendisID',
    component: loadable(() => import('./pages/inventory/InventoryEdit')),
    exact: true,
    logistic_process_enable: !featureTogglesData.logistic_process,
  },
  // Delivery
  {
    path: '/inventories/deliveries',
    component: loadable(() => import('./pages/inventory/delivery/DeliveryListing')),
    exact: true,
    logistic_process_enable: !featureTogglesData.logistic_process,
  },
  // Extensions
  {
    path: '/inventories/extensions',
    component: loadable(() => import('./pages/inventory/extensions/ExtensionWizard')),
    exact: true,
    logistic_process_enable: !featureTogglesData.logistic_process,
  },
  {
    path: '/inventories/deliveries/view/:deliveryID',
    component: loadable(() => import('./pages/inventory/delivery/DeliveryView')),
    exact: true,
    logistic_process_enable: !featureTogglesData.logistic_process,
  },
  {
    path: '/deliveries/:deliveryId',
    component: loadable(() => import('./pages/delivery/DeliveryDetails')),
    exact: true,
    logistic_process_enable: featureTogglesData.logistic_process,
  },
  {
    path: '/deliveries/:id/history',
    component: loadable(() => import('./pages/delivery/components/DeliveryHistory')),
    exact: true,
  },
  {
    path: '/deliveries',
    component: loadable(() => import('./pages/delivery/DeliveriesList')),
    exact: true,
    logistic_process_enable: featureTogglesData.logistic_process,
  },
  {
    path: '/inventories/deliveries/confirm/:deliveryID',
    component: loadable(() => import('./pages/inventory/delivery/ConfirmDelivery')),
    exact: true,
    logistic_process_enable: !featureTogglesData.logistic_process,
  },
  {
    path: '/inventories/deliveries/wizard',
    component: loadable(() => import('./pages/inventory/delivery/DeliveryWizard')),
    exact: true,
    logistic_process_enable: !featureTogglesData.logistic_process,
  },
  // Returns
  {
    path: '/inventories/returns',
    component: loadable(() => import('./pages/inventory/return/ReturnListing')),
    exact: true,
    logistic_process_enable: !featureTogglesData.logistic_process,
  },
  {
    path: '/inventories/returns/view/:returnID',
    component: loadable(() => import('./pages/inventory/return/ReturnView')),
    exact: true,
    logistic_process_enable: !featureTogglesData.logistic_process,
  },
  {
    path: '/inventories/returns/wizard',
    component: loadable(() => import('./pages/inventory/return/ReturnWizard')),
    exact: true,
    logistic_process_enable: !featureTogglesData.logistic_process,
  },
  {
    path: '/inventories/transfers',
    component: loadable(() => import('./pages/inventory/transfer/TransferListing')),
    exact: true,
    logistic_process_enable: !featureTogglesData.logistic_process,
  },
  {
    path: '/inventories/transfers/view/:transferID',
    component: loadable(() => import('./pages/inventory/transfer/TransferView')),
    exact: true,
    logistic_process_enable: !featureTogglesData.logistic_process,
  },
  {
    path: '/inventories/transfers/wizard',
    component: loadable(() => import('./pages/inventory/transfer/TransferWizard')),
    exact: true,
    logistic_process_enable: !featureTogglesData.logistic_process,
  },
]

export default routes
