import api from './api'

export const listColors = async () => {
  const language = 'de-DE'
  return api.get('/colors').then((response) => {
    response.data.sort(function (a, b) {
      return a.name[language].localeCompare(b.name[language])
    })
    return response.data
  })
}

export const createColor = async (data) => {
  return api.post('/colors', data)
}

export const updateColor = async (id, data) => {
  return api.patch(`/colors/${id}`, data).then((res) => res.data)
}

export const getColor = async (id) => {
  return api.get(`/colors/${id}`).then((res) => res.data)
}

export default { listColors, createColor, updateColor, getColor }
