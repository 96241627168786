import api from './api'

const baseEndpoint = '/deliveries'

export type DeliveryId = number | string
export type ReservedDeliveryItem = {
  warehouse_id: number
  quantity: number
}
export type DeliveryItem = {
  lendis_id: string
  contract_duration: number
  new_quantity: number
  reserved: ReservedDeliveryItem[]
}
export type DeliveryData = {
  items: DeliveryData[]
  address_id?: number
}

export const createDelivery = async (data: any) => {
  return api.post(baseEndpoint, data)
}
export const addDeliveryItems = async (id: DeliveryId, data: any) => {
  return api.post(`${baseEndpoint}/${id}/items`, data)
}

export const getDelivery = async (id: DeliveryId) => {
  return api.get(`${baseEndpoint}/${id}`).then((res) => res.data)
}

export const updateDelivery = async (id: DeliveryId, data: any) => {
  return api.patch(`${baseEndpoint}/${id}`, data).then((res) => res.data)
}
export const deleteDelivery = async (id: DeliveryId) => {
  return api.delete(`${baseEndpoint}/${id}`)
}

export const deleteDeliveryItems = async (id: DeliveryId, data: any) => {
  return api.delete(`${baseEndpoint}/${id}/items`, { data })
}

export const addSerials = async (id: DeliveryId, data: any) => {
  return api.post(`${baseEndpoint}/${id}/serial-numbers`, data).then((res) => res.data)
}

export default {
  createDelivery,
  getDelivery,
  updateDelivery,
  deleteDelivery,
  deleteDeliveryItems,
  addSerials,
  addDeliveryItems,
}
