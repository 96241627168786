import { serviceProposalApi } from './api'
import { Opportunity } from '../hooks/useOpportunities'

const getAccount = async (customerID) => {
  return serviceProposalApi.get(`account/${customerID}`).then((res) => res.data)
}

const getAccountContacts = async (customerID) => {
  return serviceProposalApi.get(`account/${customerID}/contacts`).then((res) => res.data)
}

const getAccountOpportunities = async (customerID) => {
  return serviceProposalApi
    .get<Opportunity[]>(`account/${customerID}/opportunities`)
    .then((res) => res.data)
}

export default {
  getAccount,
  getAccountContacts,
  getAccountOpportunities,
}
