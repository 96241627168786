import React, { useCallback, useEffect, useState } from 'react'
import { setI18n } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import i18n from '../i18n'

export type Locale = 'de-DE' | 'en-US'
export type Currency = 'EUR'

type ContextProps = {
  language: Locale
  currency: Currency
  availableLanguages: Locale[]
  setPreferredLanguage: (locale: Locale) => void
}

export const defaultLocale: Locale = 'de-DE'
export const defaultCurrency: Currency = 'EUR'
export const defaultPreferredLocale: Locale = navigator.language.startsWith('de')
  ? 'de-DE'
  : 'en-US'

export const LanguageContext = React.createContext<ContextProps>({
  language: defaultLocale,
  currency: defaultCurrency,
  availableLanguages: [defaultLocale],
  setPreferredLanguage: () => null,
})

setI18n(i18n)

const PREFERRED_LANGUAGE_KEY = 'j5_preferredLanguage'
const TRANSLATED_ROUTES = [
  '/proposals/',
  '/orders/',
  '/deliveries/',
  '/backlog/',
  '/assets/',
  '/customers/view/:customerID/los',
]

export const LanguageContextProvider = ({ children }: { children: React.ReactChild }) => {
  const [language, setLanguage] = useState<Locale>(defaultLocale)
  const match = useRouteMatch(TRANSLATED_ROUTES)
  const availableLanguages: Locale[] = match ? ['de-DE', 'en-US'] : [defaultLocale]

  useEffect(() => {
    if (availableLanguages.length === 1) {
      setLanguage(availableLanguages[0])
    } else {
      setLanguage(
        (localStorage.getItem(PREFERRED_LANGUAGE_KEY) as Locale) ?? defaultPreferredLocale,
      )
    }
  }, [availableLanguages.length])

  useEffect(() => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language)
    }
  }, [language])

  const setPreferredLanguage = useCallback(
    (locale: Locale) => {
      localStorage.setItem(PREFERRED_LANGUAGE_KEY, locale)
      setLanguage(locale)
    },
    [setLanguage],
  )

  return (
    <LanguageContext.Provider
      value={{
        language,
        currency: defaultCurrency,
        setPreferredLanguage,
        availableLanguages,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export default { LanguageContext, LanguageContextProvider }
