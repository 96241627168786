import { useParams } from "react-router-dom"

export enum Page {
    CustomerDetails = 'customerDetails',
    CustomerSettings = 'customerSettings',
    ChargesList = 'chargesList'
}
export type PageArguments = {
    [Page.CustomerDetails]: {
        id?: string
    },
    [Page.CustomerSettings]: never
    [Page.ChargesList]: never
}
export const PageUrlBuilders = {
    [Page.CustomerDetails]: (args: PageArguments[Page.CustomerDetails]) => `/finance/customers/${args.id ? args.id : ''}`,
    [Page.CustomerSettings]: () => `/finance/customer-settings`,
    [Page.ChargesList]: () => `/finance/charges`
} as const
type PageUrlBuildersType = typeof PageUrlBuilders
export const usePageParams = <P extends keyof PageArguments = never>(_page: P): PageArguments[P] => {
    return useParams<PageArguments[P]>()
}

//The below code allows us to avoid importing this specific enum, but still ensures correct types everywhere
//@ts-expect-error: can actually be indexed, don't believe the lies
export const getPageUrlBuilder = <P extends (keyof PageUrlBuildersType | `${keyof PageUrlBuildersType}`) = never>(page: P): (typeof PageUrlBuilders)[P] => {
    //@ts-expect-error: can actually be indexed, don't believe the lies
    return PageUrlBuilders[page]
}

export const externalLinks = {
    orderDetails: (orderId: string) => `/orders/${orderId}`,
    invoiceDefaults: () => 'https://lendis.atlassian.net/l/cp/7PAjCus8',
    financeToolingPlaybook: () => 'https://lendis.atlassian.net/l/cp/XWxisjDQ',
    proposalDetails: (proposalId: string) => `/proposals/${proposalId}`,
    customerDetails: ({ customerId }: { customerId: string }) => `/customers/view/${customerId}`
}
