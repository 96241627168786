import React, { useContext, useCallback } from 'react'
import { defaultLocale, LanguageContext } from '../context/language'
import { useTranslation as i18nextTranslation, Trans } from 'react-i18next'

export { Trans }

const useTranslation = () => {
  const { t } = i18nextTranslation()
  const { language, currency } = useContext(LanguageContext)
  const T = useCallback(
    (identifier) => {
      if (identifier === null || identifier === undefined) {
        return null
      }
      if (identifier[language]) {
        return identifier[language]
      }
      return identifier[defaultLocale]
    },
    [language],
  )
  const boolean2Text = (booleanValue: boolean) => {
    return booleanValue ? t('general.yes') : t('general.no')
  }

  const TransFormattedAnchor = props => {
    return (
      <a {...props}>{ props.children }</a>
    );
  }

  const formattedText = (key: string, args?: Record<string, string>) => {
    return (
      <Trans
        i18nKey={key}
        values={args}
        components={{ italic: <i />, bold: <strong />, br: <br />, a: <TransFormattedAnchor /> }}
      />
    )
  }

  return { t, T, currentLanguage: language, currency, boolean2Text, formattedText }
}

export default useTranslation
