import { toast } from 'react-toastify'
import React from 'react'
import FormattedError from '../theme/components/custom/FormattedError'

class NotificationSystem {
  toastID = null

  success = (msg, options) => {
    this.toastID = toast.success(msg, { className: 'notification-success', ...options })
  }

  warning = (msg, options) => {
    this.toastID = toast.warning(msg, { className: 'bg-notification-warning', ...options })
  }

  update = (options) => {
    toast.update(this.toastID, options)
  }

  error = (msg, error) => {
    this.toastID = toast.error(msg, { className: 'notification-error' })
    if (error) {
      toast.error(<FormattedError error={error} />, { className: 'notification-error' })
    }
  }
}

const notifications = new NotificationSystem()
export default notifications
