import { J5Warehouse, Warehouse, WarehouseModel } from '../entities/warehouse'
import featureTogglesData from 'featureToggles/featureTogglesData'
import api, { warehouseServiceApi } from './api'

export type UpdateWarehouse = Omit<Partial<Warehouse>, 'id'> & { id: string }
export type CreateWarehouse = Omit<Warehouse, 'id'>

const fromUpdateToJ5 = (
  updateWarehouseInput: UpdateWarehouse,
): Omit<Partial<J5Warehouse>, 'id'> & { id: number } => {
  const { addressLine1, addressLine2 } = updateWarehouseInput
  return {
    ...updateWarehouseInput,
    id: Number(updateWarehouseInput.id),
    address_line_1: addressLine1,
    address_line_2: addressLine2,
  }
}

const fromCreateToJ5 = (updateWarehouseInput: CreateWarehouse): Omit<J5Warehouse, 'id'> => {
  const { addressLine1, addressLine2 } = updateWarehouseInput
  return {
    ...updateWarehouseInput,
    address_line_1: addressLine1,
    address_line_2: addressLine2,
  }
}

const j5WarehouseService = {
  updateWarehouse: (data: UpdateWarehouse): Promise<WarehouseModel> =>
    api
      .patch(`/warehouses/${data.id}`, fromUpdateToJ5(data))
      .then((res) => WarehouseModel.create(res.data)),
  getWarehouse: (id: number | string): Promise<WarehouseModel> =>
    api.get(`/warehouses/${id}`).then((res) => WarehouseModel.create(res.data)),
  createWarehouse: (data: CreateWarehouse): Promise<string> =>
    api.post('/warehouses', fromCreateToJ5(data)).then((res) => WarehouseModel.create(res.data).id),
  getWarehouses: (include?: string[]): Promise<WarehouseModel[]> =>
    api.get(`/warehouses/?include=${include?.join(',')}`).then((res) =>
      res.data.map((getWarehouse) => {
        return WarehouseModel.create({ ...getWarehouse, ...getWarehouse.address })
      }),
    ),
}

const newWarehouseService = {
  updateWarehouse: (data: UpdateWarehouse): Promise<WarehouseModel> =>
    warehouseServiceApi
      .patch(`/warehouses/${data.id}`, data)
      .then((res) => WarehouseModel.create(res.data)),
  getWarehouse: (id: number | string): Promise<WarehouseModel> =>
    warehouseServiceApi.get(`/warehouses/${id}`).then((res) => WarehouseModel.create(res.data)),
  createWarehouse: (data: CreateWarehouse) =>
    warehouseServiceApi.post('/warehouses', data).then((res) => res.data),
  getWarehouses: (): Promise<WarehouseModel[]> =>
    warehouseServiceApi
      .get(`/warehouses`)
      .then((res) => res.data.warehouses.map(WarehouseModel.create)),
}

export default featureTogglesData.logistic_process ? newWarehouseService : j5WarehouseService
