import api, { serviceAssetApi } from './api'
import featureTogglesData from 'featureToggles/featureTogglesData'
import useAlgolia from 'hooks/useAlgolia'
import { Warehouse } from 'entities/warehouse'

export const createTransferNew = async (data: J5Transfer) => {
  const warehouseIndex = useAlgolia
    .searchClient()
    .initIndex(useAlgolia.buildIndexName('warehouses'))
  const destinationWarehouse = await warehouseIndex.getObject<Warehouse>(
    data.destination_warehouse_id,
  )
  const sourceWarehouse = await warehouseIndex.getObject<Warehouse>(data.source_warehouse_id)
  const payload: TransferPayload = {
    destinationWarehouse,
    sourceWarehouse,
    items: data.items.map((item) => ({ ...item, productId: item.lendis_id })),
    createdBy: data.createdBy,
  }
  return serviceAssetApi.post<TransferPayload>('/transfers', payload).then((res) => res.data)
}

export const createTransferJ5 = async (data) => {
  delete data.createdBy
  return api
    .post<unknown, { data: { transfer_id: string } }>('/transfers', data)
    .then((response) => {
      return response.data.transfer_id
    })
}

export const getTransfer = async (id) => {
  return api.get(`/transfers/${id}`).then((res) => res.data)
}

export const updateTransferItems = async (id, data) => {
  return serviceAssetApi
    .patch(`/transfers/${id}/transfer-items`, {
      transferItems: data.items,
      transferDate: data.transfer_date || null,
    })
    .then((res) => res.data)
}

export const updateTransferJ5 = async (id, data) => {
  return api.patch(`/transfers/${id}`, data).then((res) => res.data)
}

export const deleteTransferj5 = async (id) => {
  return api.delete(`/transfers/${id}`)
}

export const deleteAssetsTransfer = async (returnId) => {
  return serviceAssetApi
    .delete(`/transfers/${returnId}`)
    .then((res) => res.data)
    .catch((error) => {
      throw error
    })
}

export default {
  createTransfer: featureTogglesData.logistic_process ? createTransferNew : createTransferJ5,
  getTransfer,
  updateTransfer: featureTogglesData.logistic_process ? updateTransferItems : updateTransferJ5,
  deleteTransfer: featureTogglesData.logistic_process ? deleteAssetsTransfer : deleteTransferj5,
}

export type TransferPayload = {
  destinationWarehouse: Warehouse
  sourceWarehouse: Warehouse
  createdBy?: { id: number; name: string }
  items: Array<{ productId: string; quantity: number }>
}

export interface Item {
  lendis_id: string
  quantity: number
}

export interface J5Transfer {
  source_warehouse_id: string
  destination_warehouse_id: string
  items: Item[]
  createdBy?: { id: number; name: string }
}
