import useTranslation from './translation'
import { useState } from 'react'
import featureTogglesData from '../featureToggles/featureTogglesData'
import { getPageUrlBuilder as getFinancePageUrlBuilder } from '../pages/finance/pages'

export default function useMenu() {
  const { t } = useTranslation()
  type MenuType = {
    [key: string]: {
      id: string
      label: string
      icon: string
      path: string | null
      isRoot?: boolean
      children: string[]
      logistic_process_enable?: boolean
    }
  }

  const menuData: MenuType = {
    operations: {
      id: 'operations',
      label: t('top_menu.operations'),
      icon: 'fas fa-truck',
      path: null,
      isRoot: true,
      children: [
        'products',
        'logistic-cost',
        'manufacturers',
        'suppliers',
        'colors',
        'warehouses',
        'buyers',
        'inventory',
        'orders',
        'backlog',
        'assets',
        'delivery',
      ],
    },
    marketing: {
      id: 'marketing',
      label: t('top_menu.marketing'),
      icon: 'fas fa-bullhorn',
      path: null,
      isRoot: true,
      children: ['leads', 'campaignMapping'],
    },
    campaignMapping: {
      id: 'campaignMapping',
      label: t('top_menu.marketing.campaign_mapping'),
      icon: 'fas fa-campground',
      path: '/marketing/campaign-mapping',
      children: [],
    },
    inventory: {
      id: 'inventory',
      label: t('top_menu.operations.inventory'),
      icon: 'fas fa-archive',
      path: '/inventories',
      children: ['deliveries', 'returns', 'transfers'],
      logistic_process_enable: !featureTogglesData.logistic_process,
    },
    assets: {
      id: 'assets',
      label: t('top_menu.operations.assets'),
      icon: 'fas fa-archive',
      path: '/assets',
      children: ['deliveries', 'returns', 'transfers'],
      logistic_process_enable: featureTogglesData.logistic_process,
    },
    deliveries: {
      id: 'deliveries',
      label: t('top_menu.operations.deliveries'),
      icon: 'fas fa-truck-loading',
      path: '/inventories/deliveries',
      children: [],
    },
    returns: {
      id: 'returns',
      label: t('top_menu.operations.returns'),
      icon: 'fas fa-undo-alt',
      path: '/inventories/returns',
      children: [],
    },
    transfers: {
      id: 'transfers',
      label: t('top_menu.operations.transfers'),
      icon: 'fas fa-exchange-alt',
      path: '/inventories/transfers',
      children: [],
    },
    sales: {
      id: 'sales',
      label: t('top_menu.sales'),
      icon: 'fas fa-hand-holding-usd',
      path: null,
      isRoot: true,
      children: ['customers', 'proposals'],
    },
    finance: {
      id: 'finance',
      label: t('top_menu.finance'),
      icon: 'fas fa-money-bill-wave',
      path: null,
      isRoot: true,
      children: ['inventory_data', 'order_data', 'finance_customer_settings', 'finance_customers', 'finance_charges'],
    },

    admin: {
      id: 'admin',
      label: t('top_menu.admin'),
      icon: 'fas fa-users-cog',
      path: null,
      isRoot: true,
      children: ['admin-users'],
    },
    customers: {
      id: 'customers',
      label: t('top_menu.sales.customers'),
      icon: 'fab fa-salesforce',
      path: '/customers',
      children: [],
    },
    inventory_data: {
      id: 'inventory_data',
      label: t('top_menu.finance.inventory'),
      icon: 'fas fa-database',
      path: '/finance',
      children: [],
    },
    order_data: {
      id: 'order_data',
      label: t('top_menu.finance.order'),
      icon: 'fas fa-database',
      path: '/finance-order',
      children: [],
    },
    finance_customer_settings: {
      id: 'finance_customer_settings',
      label: t('top_menu.finance.customer_settings'),
      icon: 'fas fa-database',
      path: `${getFinancePageUrlBuilder('customerSettings')()}`,
      children: []
    },
    finance_customers: {
      id: 'finance_customers',
      label: t('top_menu.finance.customer_details'),
      icon: 'fas fa-database',
      path: `${getFinancePageUrlBuilder('customerDetails')({})}`,
      children: []
    },
    finance_charges: {
      id: 'finance_charges',
      label: t('top_menu.finance.charges'),
      icon: 'fas fa-database',
      path: `${getFinancePageUrlBuilder('chargesList')()}`,
      children: []
    },
    proposals: {
      id: 'proposals',
      label: t('top_menu.sales.proposals'),
      icon: 'fab fa-salesforce',
      path: '/proposals',
      children: [],
    },
    colors: {
      id: 'colors',
      label: t('top_menu.operations.colors'),
      icon: 'fas fa-palette',
      path: '/colors',
      children: [],
    },
    warehouses: {
      id: 'warehouses',
      label: t('top_menu.operations.warehouses'),
      icon: 'fas fa-warehouse',
      path: '/warehouses',
      children: [],
    },
    leads: {
      id: 'leads',
      label: t('top_menu.marketing.leads'),
      icon: 'fas fa-ad',
      path: '/marketing/leads',
      children: [],
    },
    adminUsers: {
      id: 'admin-users',
      label: t('top_menu.admin.users'),
      icon: 'fas fa-user',
      path: '/admin/users',
      children: [],
    },
    logisticCost: {
      id: 'logistic-cost',
      label: t('top_menu.operations.logistic-cost'),
      icon: 'fas fa-user',
      path: '/logistic-cost',
      children: [],
    },
    manufacturers: {
      id: 'manufacturers',
      label: t('top_menu.operations.manufacturers'),
      icon: 'fas fa-industry',
      path: '/manufacturers',
      children: [],
    },
    suppliers: {
      id: 'suppliers',
      label: t('top_menu.operations.suppliers'),
      icon: 'fas fa-parachute-box',
      path: '/suppliers',
      children: [],
    },
    buyers: {
      id: 'buyers',
      label: t('top_menu.operations.buyers'),
      icon: 'fas fa-credit-card',
      path: '/buyers',
      children: [],
    },
    products: {
      id: 'products',
      label: t('top_menu.operations.products'),
      icon: 'fas fa-box-open',
      path: '/products',
      children: [],
    },
    orders: {
      id: 'orders',
      label: t('top_menu.operations.orders'),
      icon: 'fas fa-gifts',
      path: '/orders',
      children: [],
    },
    backlog: {
      id: 'backlog',
      label: t('top_menu.operations.backlog'),
      icon: 'fas fa-gifts',
      path: '/backlog',
      children: [],
      logistic_process_enable: featureTogglesData.logistic_process,
    },
    delivery: {
      id: 'delivery',
      label: t('top_menu.operations.deliveries'),
      icon: 'fas fa-truck-loading',
      path: '/deliveries',
      children: [],
      logistic_process_enable: featureTogglesData.logistic_process,
    },
  }

  const filteredMenu: MenuType = Object.keys(menuData)
    .filter((key) => {
      return menuData[key].logistic_process_enable !== false;
    })
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: menuData[key],
      }
    }, {})

  const [menu, setMenu] = useState(filteredMenu)

  return { menu, setMenu }
}
