import i18n from 'i18next'
const germanTranslation = require('./theme/translation/de_DE.json')
const englishTranslation = require('./theme/translation/en_US.json')

export enum Translation {
  DE = 'de-DE',
  EN = 'en-US',
}

type ExtractLanguages<T> = T extends `${infer U}-${string}` ? U : T;

const supportedLanguages: ExtractLanguages<Translation>[] = []

for (const lang in Translation) {
  supportedLanguages.push(Translation[lang].substr(0, 2))
}

export { supportedLanguages }

const getStoredTranslations = (translation: Translation) => {
  if (translation === Translation.DE) {
    return germanTranslation
  } else {
    return englishTranslation
  }
}

export type LocalizedString = {
  'de-DE': string
  'en-US'?: string
}

i18n.init({
  whitelist: Object.values(Translation),
  fallbackLng: [Translation.EN, Translation.DE],
  lng: Translation.DE,
  keySeparator: '/',
  initImmediate: false,
  preload: Object.values(Translation),
  debug: false,
  resources: Object.values(Translation).reduce(
    (acc: { [key: string]: { translation: string } }, translationKey) => {
      acc[translationKey] = { translation: getStoredTranslations(translationKey) }
      return acc
    },
    {},
  ),
  returnEmptyString: true,
  returnNull: true,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
    format(value, format) {
      if (format === 'uppercase') return value.toUpperCase()
      return value
    },
  },
})

export default i18n
