import { InfoCircleOutlined } from '@ant-design/icons'
import { Space, Tooltip } from 'antd'
import React from 'react'
import useTranslation from '../../../../hooks/translation'

interface Props {
  name?: string | undefined
  description?: string | undefined
  lendis_id?: null | string
}

const ProductName: React.FC<Props> = ({ name = '', description = '', lendis_id = null }) => {
  const { T } = useTranslation()
  let desc = ''
  if (description !== '' || description !== undefined) {
    if (T(description)?.length) {
      desc =
        T(description).length > 50
          ? T(description)
              .split(',')
              .map((piece, i) => {
                return <p key={`prod-name-desc-${i}-${Date.now()}`}>{piece}</p>
              })
          : T(description)
    }
  }

  return (
    <React.Fragment>
      <Tooltip placement="left" title={desc}>
        <Space size={8}>
          {desc && <InfoCircleOutlined />}
          {T(name)}
        </Space>
      </Tooltip>
      {lendis_id && <p>{lendis_id}</p>}
    </React.Fragment>
  )
}

export default ProductName
