import { serviceProposalApi } from './api'
import { Proposal } from '../hooks/useProposal'
import { DraftProposal } from '../hooks/useProposalWizard'
import { BillingAddress } from '../hooks/useBillingAddress'
import { Account } from '../hooks/useCustomerAccount'
import queryString from 'qs'

type ProposalCloneAdditionalMetaData =
  | {
      orderId: string
      method: 'oli-replace-clone'
    }
  | { method: 'normal-clone' }

export const createProposal = async (name, email) => {
  return serviceProposalApi
    .post<{ id: string; state: 'draft' }>(`proposals`, {
      salesAgent: {
        email,
        name,
      },
    })
    .then((res) => res.data)
}

export const createProposalLog = async (proposalId: string, action: string, metadata = {}) => {
  return serviceProposalApi.post(`proposals/${proposalId}/logs`, { action, metadata })
}

export const getBillingAddress = async (customerID: string) => {
  return serviceProposalApi
    .get<BillingAddress>(`account/${customerID}/billingAddress`)
    .then((res) => res.data)
}

export const getAccount = async (customerID: string) => {
  return serviceProposalApi.get<Account>(`account/${customerID}`).then((res) => res.data)
}

export const getProposal = async (proposalID: string) => {
  return serviceProposalApi.get<Proposal>(`proposals/${proposalID}`).then((res) => res.data)
}

export const updateProposal = async (proposalID: string, proposalData: DraftProposal) => {
  return serviceProposalApi
    .patch<DraftProposal>(`proposals/${proposalID}`, proposalData)
    .then((res) => res.data)
}

export const publishProposal = async (proposalID: string) => {
  return serviceProposalApi.post<null>(`proposals/${proposalID}/publish`).then((res) => res.data)
}

export const cloneProposal = async (
  proposalID: string,
  salesAgent: {
    name: string
    email: string
  },
  additional: ProposalCloneAdditionalMetaData = { method: 'normal-clone' },
) => {
  return serviceProposalApi
    .post<DraftProposal>(`proposals/${proposalID}/clone?${queryString.stringify(additional)}`, {
      salesAgent,
    })
    .then((res) => res.data)
}

const downloadPDF = (proposalID: string) => {
  return serviceProposalApi.get(`proposals/${proposalID}/pdf`)
}

const downloadExcel = (proposalID: string) => {
  return serviceProposalApi.get(`proposals/${proposalID}/excel`)
}

export default {
  createProposal,
  getProposal,
  updateProposal,
  getBillingAddress,
  getAccount,
  cloneProposal,
  downloadPDF,
  createProposalLog,
  downloadExcel,
}
