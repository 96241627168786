import { Layout, Menu } from 'antd'
import LogisticProcessToggle from 'featureToggles/LogisticProcessToggle'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { UserContext } from '../../../../context/user'
import useTranslation from '../../../../hooks/translation'
import useMenu from '../../../../hooks/useMenu'
import { logout } from '../../../../services/auth'
import logo from '../../../assets/images/logo-transparent.png'
import HeaderLanguageToggle from './HeaderLanguageToggle'
import HeaderSearch from './HeaderSearch'

const { SubMenu } = Menu

const HeaderNavigation = () => {
  const { user } = useContext<any>(UserContext)
  const { menu } = useMenu()
  const history = useHistory()
  const location = useLocation()
  const [current, setCurrent] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    Object.values(menu)
      .filter((m) => m.path !== null)
      .forEach((m: any) => {
        if (location.pathname.includes(m.path)) {
          setCurrent(m.path)
          return
        }
      })
  }, [location, menu])

  const handleLogout = () => {
    logout()
    history.push('/login')
  }
  const setting = (
    <Menu mode="horizontal" style={{ border: 'none' }}>
      <SubMenu key="user-settings" title={user.details.name} style={{ padding: '0 0.75rem' }}>
        <Menu.Item key="auth-token">
          <p
            onClick={() => {
              window.prompt(t('top_menu.user.copy_token.note'), localStorage.access_token)
            }}
          >
            {t('top_menu.user.copy_token')}
          </p>
        </Menu.Item>
        <Menu.Item key="#">
          <LogisticProcessToggle />
        </Menu.Item>
        <Menu.Item key="logout" danger onClick={handleLogout}>
          {t('top_menu.user.sign-out')}
        </Menu.Item>
      </SubMenu>
    </Menu>
  )

  return (
    <Layout.Header
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        padding: '0px 10px',
      }}
    >
      <img
        height={40}
        src={logo}
        alt="Lendis"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          history.push('/')
        }}
      />
      <Menu mode="horizontal" selectedKeys={current} style={{ flexGrow: 2, border: 'none' }}>
        {Object.values(menu)
          .filter((me: any) => me.isRoot === true)
          .map((child) => {
            return (
              <SubMenu key={child.id} icon={<i className={`${child.icon} `} />} title={child.label}>
                {child.children.map((ch) => {
                  const item: any = Object.values(menu).find((i) => i.id === ch)

                  if (!item) return

                  return (
                    <Menu.Item
                      icon={<i style={{ padding: '0 1rem' }} className={`${item.icon}`} />}
                      key={item.path}
                    >
                      <Link key={item.id} to={item.path}>
                        {item.label}
                      </Link>
                    </Menu.Item>
                  )
                })}
              </SubMenu>
            )
          })}
      </Menu>
      <HeaderSearch />
      {setting}
      <HeaderLanguageToggle />
    </Layout.Header>
  )
}

export default HeaderNavigation
