import api, { serviceProductApi } from './api'

export async function setRelatedProduct(parentProductId, linkProductIds) {
  return api.post(`products/${parentProductId}/relation`, linkProductIds)
}

export async function removeRelatedProduct(productID) {
  return api.delete(`products/${productID}/relation`)
}

/**
 * Product ID or Lendis ID
 * @param productID
 */
export async function getProduct(productID) {
  return api.get(`products/${productID}`).then((response) => response.data)
}

export async function getWarehouses(productID) {
  return api.get(`products/${productID}/warehouses`).then((response) => response.data)
}

export const getProductList = (data) => {
  return serviceProductApi
    .post('/products/list', data)
    .then((res) => res.data)
    .catch((error) => {
      throw error
    })
}

export async function getAllProductsWarehouses(productIDs) {
  return api.post(`products/warehouses`, productIDs).then((response) => response.data)
}

export default {
  setRelatedProduct,
  removeRelatedProduct,
  getProduct,
  getWarehouses,
  getAllProductsWarehouses,
  getProductList,
}
