import React, { useEffect, useState } from 'react'
import { AutoComplete, Input } from 'antd'
import { debounce } from 'debounce'
import useTranslation from '../../../../hooks/translation'
import useClipboard from '../../../../hooks/useClipboard'
import { search as searchAPI } from '../../../../services'
import ProductName from '../ProductName/ProductName'
import algolia from '../../../../services/algolia'

const unshiftIfExists = (arr: string[], value: string): string[] => {
  if (arr.includes(value)) {
    return [value, ...arr.filter((item) => item !== value)]
  }
  return arr
}

const displayGroupName = (indexName) => {
  switch (indexName) {
    case 'new_verticals':
      return 'new verticals'
    case 'marketing':
      return 'marketing campaign'
  }
  return indexName
}

const renderGroup = (groupName: string) => <span>{displayGroupName(groupName)}</span>

const renderItem = (T: (identifier: any) => string, indexName: string, hit: any) => {
  const title = typeof hit.name === 'string' ? hit.name : T(hit.name)
  const akeneoBaseURL = process.env.REACT_APP_AKENEO_BASE_URL ?? null

  let provideLink = ''
  let provideName = ''
  let showDescription = false
  let showCampaignMapIcons = false
  switch (indexName) {
    case 'products':
      provideLink = `${akeneoBaseURL}#/enrich/product/?code=${hit.lendis_id}`
      provideName = hit.lendis_id
      showDescription = true
      break
    case 'leads':
      provideLink = `/marketing/leads/view/${hit.id}`
      provideName = hit.email
      break
    case 'warehouses':
      provideLink = `/warehouses/edit/${hit.id}`
      provideName = hit.email
      break
    case 'deliveries':
      provideLink = `/inventories/deliveries/view/${hit.delivery_id}`
      provideName = hit.delivery_id
      break
    case 'returns':
      provideLink = `/inventories/returns/view/${hit.return_id}`
      provideName = hit.return_id
      break
    case 'transfers':
      provideLink = `/inventories/transfers/view/${hit.transfer_id}`
      provideName = hit.transfer_id
      break
    case 'marketing_campaign_mappings':
      provideLink = `/marketing/campaign-mapping/${hit.id}/view/`
      provideName = `${hit.campaign_id} | ${hit.list_id} | `
      showCampaignMapIcons = true
      break
    default:
      provideLink = `/${indexName}/view/${hit.id}`
      provideName = hit.lendis_id
      break
  }
  return {
    value: provideName.trim(),
    label: (
      <a
        data-testid={`result-items`}
        href={provideLink}
        onClick={(e) => {
          e.preventDefault()
        }}
      >
        {title}
        <span style={{ margin: '0 4px 0 2px' }}>{provideName}</span>
        {showDescription && <ProductName description={hit.description} />}
        {showCampaignMapIcons && (
          <span style={{ marginLeft: '2px' }}>
            {hit.type === 'linkedin' && <i className="fab fa-linkedin" />}
            {hit.type === 'facebook' && <i className="fab fa-facebook" />}
          </span>
        )}
      </a>
    ),
  }
}

interface AutoCompleteOption {
  label: React.ReactFragment
  options: any[]
}

const hitsToOption = (
  T: (identfier: any) => string,
  indexName: string,
  hits: any[],
): AutoCompleteOption => {
  return {
    label: <span style={{ textTransform: 'uppercase' }}>{renderGroup(indexName)}</span>,
    options: hits.map((hit) => renderItem(T, indexName, hit)),
  }
}

const HeaderSearch: React.FC = () => {
  const { T } = useTranslation()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [options, setOptions] = useState<AutoCompleteOption[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { copyTextToClipboard } = useClipboard()

  const searchClient = algolia.searchClient
  const indexName = algolia.buildIndexName('products')
  const AlgoliaIndex = searchClient.initIndex(indexName)

  useEffect(() => {
    if (searchTerm === '' && options.length > 0) {
      setOptions([])
      return
    } else if (searchTerm === '') {
      return
    }

    ;(async () => {
      setLoading(true)
      const rsl = await searchAPI.get(searchTerm)

      const products = await AlgoliaIndex.search(searchTerm, {
        hitsPerPage: 1000,
      })

      rsl['products'] = products

      let keys = Object.keys(rsl).filter((k) => rsl[k].hits.length > 0)
      keys = unshiftIfExists(keys, 'leads')
      keys = unshiftIfExists(keys, 'new_verticals')
      keys = unshiftIfExists(keys, 'furniture')

      const mapped = keys
        .filter((k) => rsl[k].hits.length > 0)
        .map((k) => {
          return hitsToOption(T, k, rsl[k].hits)
        })

      setOptions([...mapped])
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, T, options.length])

  const onSearch = (input) => {
    setSearchTerm(input)
  }

  const onSelect = async (value, e) => {
    const toBeHref = e.label.props.href
    if (!toBeHref.startsWith('https://')) {
      document.location.href = toBeHref
    } else {
      await copyTextToClipboard(value)
      window.open(toBeHref)
    }
  }

  return (
    <AutoComplete
      data-testid="autocomplete-element"
      onSearch={debounce(onSearch, 300)}
      options={options}
      dropdownMatchSelectWidth={false}
      onSelect={onSelect}
    >
      <Input.Search
        data-testid="search-input"
        size="middle"
        placeholder="input here"
        loading={loading}
        style={{ marginTop: -4 }}
      />
    </AutoComplete>
  )
}

export default HeaderSearch
