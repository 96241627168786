import { ProductImgUrl } from '../hooks/useProposal'
import { Customer } from '../hooks/useCustomer'
import api from './api'

// Attention: Those models are only related to the temporary edit-inventory functionality

interface Vertical {
  id: 1
  name: {
    'de-DE': string
  }
  slug: string
}

interface Color {
  code: string | null
  name: {
    'de-DE': string
  }
  id: number
}

interface Category {
  id: number
  name: {
    'de-DE': string
  }
  slug: string
  vertical_id: number
  vertical?: Vertical
}

interface SubCategory {
  id: number
  name: {
    'de-DE': string
  }
  slug: string
  category_id: number
  category?: Category
}

export interface InventoryLastState {
  status: string
  date: string
  buyer_id?: string | null
}

export interface InventoryBulkSold {
  inventory_ids: number[]
  date: string
  buyer_id: string | null
}

export interface Product {
  id: number
  lendis_id: string
  name: {
    'de-DE': string
  }
  description: {
    'de-DE': string
  }
  product_group_id: number
  subcategory_id: number
  color_id: number
  sellable: string
  sourcing_price: number
  productable_type: string
  productable_id: number
  category?: Category
  publishing_year?: number
  subcategory?: SubCategory
  color?: Color
  images?: ProductImgUrl[]
  vertical?: Vertical
}

export interface InventoryHit {
  buyer?: any
  comment: string | null
  created_at: string
  current_address: Address
  current_customer: Customer
  current_location: InventoryLocation
  current_location_id: number
  current_warehouse: string | null
  damages: string | null
  deleted_at: string | null
  delivery_id: string
  disposed_date: string | null
  end_date: string | null
  end_date_timestamp: number
  fk_buyer: number | null
  id: number
  inventory_id: string
  is_billable: boolean
  is_reserved: boolean
  is_warehouse: boolean
  location: string
  objectID: string
  product?: Product
  product_id: number
  proposal: string
  serial_number?: string
  start_date: string | null
  start_date_timestamp: number
  status: string
  stolen_date: string | null
  updated_at: string
}

type Address = {
  address_line_1: string
  address_line_2?: string
  city: string
  country: string
  created_at: Date
  deleted_at?: Date
  id: number
  number: string
  street: string
  updated_at: Date
  zipcode: string
}

export interface InventoryLocation {
  id: number
  inventory_id: number
  start_date: string | null
  end_date: string | null
  proposal: string | null
  status: string | null
  customer_address_id: number | null
  warehouse_id: number | null
  imported: boolean
  contract_duration: number
}

// InventoryDETAILS because the model differs from the algolia model
export interface InventoryDetails {
  id: number
  product_id: number
  product_lendis_id?: number
  current_location_id: number
  inventory_id: string
  customer_id: number
  fk_buyer: number | null
  status: string
  is_billable: boolean
  comment: string | null
  damages: string | null
  stolen_date: string | null
  disposed_date: string | null
  serial_number?: string
  is_reserved: boolean
  buyer?: any
  product?: Product
  locations?: InventoryLocation[]
  currentLocation?: InventoryLocation
}

export const getInventory = (inventoryID): Promise<InventoryDetails> =>
  api
    .get(`/inventories/${inventoryID}`)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err)
      throw err
    })

export const getInventoryLocations = (
  inventoryID,
  include?: string[],
): Promise<InventoryLocation[]> =>
  api
    .get(`/inventories/${inventoryID}/locations?include=${include ? include.join(',') : ''}`)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err)
      throw err
    })

export const updateInventory = (inventoryID: number, data: any): Promise<InventoryDetails> =>
  api
    .patch(`/inventories/${inventoryID}`, data)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err)
      throw err
    })

export const setInventoryLastState = (
  inventoryID: number,
  data: InventoryLastState,
): Promise<InventoryDetails> =>
  api
    .post(`/inventories/${inventoryID}/last-state`, data)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err)
      throw err
    })

export const markInventoriesAsSold = (data: InventoryBulkSold): Promise<InventoryDetails> =>
  api
    .patch(`/inventories/bulk-sold`, data)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err)
      throw err
    })

export const unsetInventoryLastState = (inventoryID: number): Promise<InventoryDetails> =>
  api
    .delete(`/inventories/${inventoryID}/last-state`)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err)
      throw err
    })

const Inventories = {
  getInventory,
  setInventoryLastState,
  unsetInventoryLastState,
  markInventoriesAsSold,
}

export default Inventories
