import { serviceProductPriceApi, gotenbergPDFApi } from './api'

type CalculateFlexOptionsRates = {
  rentalPeriod: number
  pricePerMonth: number
}

export type CalculateFlexOptionsRequest = {
  contractRentalPeriod: number
  targetRentalPeriod: number
  taxRate: number
  rates: CalculateFlexOptionsRates[]
}

export type CalculateFlexOptionsResponseEntry = {
  period: number
  penalty: number
  penaltyWithTax: number
  rate: number
}

type CalculateFlexOptionsResponseTotal = {
  penalty: number
  penaltyWithTax: number
}

export type CalculateFlexOptionsResponse = {
  total: CalculateFlexOptionsResponseTotal
  list: {
    contractRentalPeriod: number
    targetRentalPeriod: number
    taxRate: number
    rates: CalculateFlexOptionsRates[]
    flexOption: CalculateFlexOptionsResponseEntry
  }[]
}

export const calculateFlexOptions = async (data: CalculateFlexOptionsRequest[]) => {
  return serviceProductPriceApi
    .post<CalculateFlexOptionsResponse>('calculate/flex-options-penalty', data)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err)
      throw err
    })
}

export const downloadCancellationRequestPDF = async (data: FormData): Promise<string> => {
  try {
    return (await gotenbergPDFApi.post('convert/html', data)).data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export default {
  calculateFlexOptions,
  downloadCancellationRequestPDF,
}
