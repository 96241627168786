import React, { useState } from 'react'
import algolia from '../services/algolia'

type ContextProps = {
  refreshRequired: any
  setRefreshRequired: any
  algolia: any
}

export const AlgoliaContext = React.createContext<Partial<ContextProps>>({})

export const AlgoliaContextProvider: React.FC = ({ children }) => {
  const [refreshRequired, setRefreshRequired] = useState(false)
  const { searchClient } = algolia
  if (refreshRequired) {
    searchClient.clearCache()
  }
  return (
    <AlgoliaContext.Provider value={{ refreshRequired, setRefreshRequired, algolia }}>
      {children}
    </AlgoliaContext.Provider>
  )
}
