import api from './api'

export async function setupSubCategory(subCategoryID) {
  return api
    .get(`/subcategories/${subCategoryID}/setup`)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err)
    })
}

export default { setupSubCategory }
