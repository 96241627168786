import api from './api'

export async function listManufacturers() {
  return api
    .get('/manufacturers', { params: { perPage: 99999, sort: 'name' } })
    .then((response) => response.data.data)
}

export const getManufacturer = async (id) => {
  return api.get(`/manufacturers/${id}`).then((res) => res.data)
}

export const createManufacturer = async (data) => {
  return api.post('/manufacturers', data)
}

export const updateManufacturer = async (id, data) => {
  return api.patch(`/manufacturers/${id}`, data).then((res) => res.data)
}

export default { getManufacturer, listManufacturers, createManufacturer, updateManufacturer }
