import api from './api'

export async function listBuyers() {
  return api.get('/buyers').then((response) => response.data)
}

export const createBuyer = async (data) => {
  return api.post('/buyers', data)
}

export const updateBuyer = async (id, data) => {
  return api.patch(`/buyers/${id}`, data).then((res) => res.data)
}

export const getBuyer = async (id) => {
  return api.get(`/buyers/${id}`).then((res) => res.data)
}

export default { listBuyers, createBuyer, updateBuyer, getBuyer }
