import api from './api'

export async function listSuppliers() {
  return api
    .get('/suppliers', { params: { perPage: 99999, sort: 'name' } })
    .then((response) => response.data.data)
}

export const createSupplier = async (data) => {
  return api.post('/suppliers', data)
}

export const updateSupplier = async (id, data) => {
  return api.patch(`/suppliers/${id}`, data).then((res) => res.data)
}

export const getSupplier = async (id) => {
  return api.get(`/suppliers/${id}`).then((res) => res.data)
}

export default { listSuppliers, createSupplier, updateSupplier, getSupplier }
