import api from './api'

export async function updateElement(element) {
  return api.patch(`/elements/${element.id}`, { ...element }).then((response) => response.data)
}

export const createElement = async (furnitureID, data) => {
  return api.post(`/furniture/${furnitureID}/elements`, data)
}

export const deleteElement = async (elementID) => {
  return api.delete(`/elements/${elementID}`)
}

export default { updateElement, createElement, deleteElement }
