import { Switch } from 'antd'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import featureTogglesData, { toggleFeature } from './featureTogglesData'

function toggleLogisticProcess(currentState: boolean) {
  toggleFeature('logistic_process', !currentState)
}

export default function LogisticProcessToggle(): ReactElement<void, any> {
  const { t } = useTranslation()
  const currentState = featureTogglesData.logistic_process

  return (
    <label>
      {t('feat.new-logistic-process')}
      <Switch
        style={{ marginLeft: '1em' }}
        defaultChecked={currentState}
        onChange={() => {
          toggleLogisticProcess(currentState)
          window.location.reload()
        }}
      />
    </label>
  )
}
