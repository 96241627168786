import api from './api'

export const update = (id, slug, data) => {
  return api.patch(`/${slug}/${id}`, data).then((res) => res.data)
}

export const updateLogisticCosts = (id, slug, data) => {
  return api.put(`/logistic-costs/verticals/${slug}/${id}`, data).then((res) => res.data)
}

export const createNewVertical = (slug, data) => {
  return api.post(`/${slug}`, data).then((res) => res.data)
}

export async function getProduct(slug, newVerticalID) {
  return api.get(`/${slug}/${newVerticalID}`).then((res) => res.data)
}

export default { update, createNewVertical, getProduct, updateLogisticCosts }
