import api from './api'
import { InventoryDetails } from './inventories'

export const createCustomer = (data) => {
  return api.post('/customers', data).then((res) => res.data)
}

export const createCustomerAddress = (id, data) => {
  return api.post(`/customers/${id}/addresses`, data)
}

export const createCustomerContact = async (id, data) => {
  return api.post(`/customers/${id}/contacts`, data)
}

export const updateCustomer = (id, data) => {
  return api.patch(`/customers/${id}`, data).then((res) => res.data)
}

export const updateCustomerAddress = (id, addressId, data) => {
  return api.patch(`/customers/${id}/addresses/${addressId}`, data).then((res) => res.data)
}

export const updateCustomerContact = (id, contactId, data) => {
  return api.patch(`/customers/${id}/contacts/${contactId}`, data).then((res) => res.data)
}

export const deleteCustomerContact = (id, contactId) => {
  return api.delete(`/customers/${id}/contacts/${contactId}`)
}

export const deleteCustomerAddress = (id, addressId) => {
  return api.delete(`/customers/${id}/addresses/${addressId}`)
}

export const deleteCustomer = (id) => {
  return api.delete(`/customers/${id}`)
}

export const setup = () => {
  return api.get('customers/setup').then((res) => res.data)
}

export const getCustomer = (id) => {
  return api.get(`/customers/${id}`).then((res) => res.data)
}

export const getCustomerAddresses = (id, include) => {
  return api
    .get(`/customers/${id}/addresses?include=${include ? include.join(',') : ''}`)
    .then((res) => res.data)
}

export const getCustomerProducts = (id) => {
  return api.get(`/customers/${id}/products`).then((res) => res.data)
}

export const getCustomerProductsByAddress = (addressId) => {
  return api.get(`addresses/${addressId}/products`).then((res) => res.data)
}

export const createWebAppUser = (customerID: number, data: any): Promise<InventoryDetails> =>
  api.post(`/webapp/${customerID}/users`, data).then((res) => res.data)

export default {
  getCustomer,
  setup,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  createCustomerAddress,
  updateCustomerAddress,
  deleteCustomerAddress,
  createCustomerContact,
  updateCustomerContact,
  deleteCustomerContact,
  getCustomerProducts,
  getCustomerProductsByAddress,
  createWebAppUser,
}
