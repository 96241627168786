import loadable from "@loadable/component"
import React from "react"
import { Page, getPageUrlBuilder } from "./pages"

type Route = {
    component: React.ComponentType,
    exact: boolean
    path: string
}

export const routes: Route[] = [
    {
        path: getPageUrlBuilder('customerDetails')({ id: ':id?' }),
        exact: true,
        component: loadable(() => import("./CustomerSettingsPage"))
    },
    {
        path: getPageUrlBuilder('customerSettings')(),
        exact: true,
        component: loadable(() => import("./CustomerSettingsListPage"))
    },
    {
        path: getPageUrlBuilder('chargesList')(),
        exact: true,
        component: loadable(() => import("./ChargesPage"))
    }
]

export { Page }
