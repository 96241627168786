import React, { useContext } from 'react'
import { Select } from 'antd'
import { LanguageContext, Locale } from 'context/language'

const labels: { [Key in Locale]: { flag: string; label: string } } = {
  'de-DE': { flag: '🇩🇪', label: 'Deutsch' },
  'en-US': { flag: '🇺🇸', label: 'English' },
}

const HeaderLanguageToggle = () => {
  const { availableLanguages, language, setPreferredLanguage } = useContext(LanguageContext)

  return (
    <Select
      value={language}
      onChange={setPreferredLanguage}
      optionLabelProp="label"
      style={{ width: '8rem' }}
      disabled={availableLanguages.length < 2}
    >
      {availableLanguages.map((locale) => (
        <Select.Option
          value={locale}
          key={locale}
          label={
            <div>
              <span style={{ marginRight: '0.75rem' }}>{labels[locale].flag}</span>
              <span>{labels[locale].label}</span>
            </div>
          }
        >
          <div style={{ fontWeight: 500 }}>
            <span style={{ marginRight: '0.75rem' }}>{labels[locale].flag}</span>
            <span>{labels[locale].label}</span>
            <span style={{ float: 'right' }}>{locale === language && '✓'}</span>
          </div>
        </Select.Option>
      ))}
    </Select>
  )
}

export default HeaderLanguageToggle
