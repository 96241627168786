import JWTDecode from 'jwt-decode'
import api from './api'

// TODO CHECK EXPIRATION
export const isLoggedIn = () => {
  const token = localStorage.getItem('access_token')
  return token !== '' && token !== null
}
export const getToken = () => localStorage.getItem('access_token')
const emptyUser = { details: { avatar: '', name: '' } }
export const setToken = (token) => localStorage.setItem('access_token', token)
export const currentUser = () => {
  if (!isLoggedIn()) {
    return emptyUser
  }
  const token = getToken()
  const { user } = JWTDecode(token)
  return user
}

export const login = async (token) => {
  return api
    .post('/login', { token })
    .then((res) => {
      setToken(res.data.access_token)
      return Promise.resolve(currentUser())
    })
    .catch((err) => {
      console.error(err)
      return Promise.reject(err)
    })
}

export const logout = () => {
  localStorage.removeItem('access_token')
}

export default { isLoggedIn, getToken, setToken, currentUser, login, logout }
