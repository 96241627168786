import algoliasearch from 'algoliasearch/lite'

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
)

const buildIndexName = (id) => {
  return `${process.env.REACT_APP_ALGOLIA_API_PREFIX.replace(/_+$/, '')}_${id}`
}

export default { searchClient, buildIndexName }
