import React from 'react'

type FormattableError = Error & {
  response?: {
    data?: unknown
  }
}
const FormattedError = ({ error }: { error: FormattableError }) => (
  <div>
    {error.message}
    {error.response?.data && (
      <div style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(error.response?.data, null, 2)}</div>
    )}
  </div>
)

export default FormattedError
