const FEATURE_TOGGLES_KEY_NAME = 'featureTogglesData'

const featureToggles = {
  staging: {
    logistic_process: true,
    pgt_promotions: true,
  },
  production: {
    logistic_process: false,
    pgt_promotions: false,
  },
  local: {
    logistic_process: true,
    pgt_promotions: true,
  },
  staging2: {
    logistic_process: true,
    pgt_promotions: true,
  },
}

type FeatureToggleState = typeof featureToggles[keyof typeof featureToggles]

function isValidEnvironment(
  environment: typeof process.env.REACT_APP_NODE_ENV,
): environment is keyof typeof featureToggles {
  return Object.keys(featureToggles).includes(String(environment))
}

if (!isValidEnvironment(process.env.REACT_APP_NODE_ENV)) {
  throw new Error('REACT_APP_NODE_ENV must be one of: ' + Object.keys(featureToggles).join('|'))
}

function persistFeatureToggles(featureToggleState: FeatureToggleState) {
  window.localStorage.setItem(FEATURE_TOGGLES_KEY_NAME, JSON.stringify(featureToggleState))
}

function getPersistedFeatureToggleState(): FeatureToggleState | undefined {
  const featureToggleState = window.localStorage.getItem(FEATURE_TOGGLES_KEY_NAME)

  if (featureToggleState) {
    try {
      return JSON.parse(featureToggleState)
    } catch {
      // pass
    }
  }
  return undefined
}

const featureTogglesData: FeatureToggleState =
  getPersistedFeatureToggleState() || featureToggles[process.env.REACT_APP_NODE_ENV]

export function toggleFeature(feature: keyof FeatureToggleState, newState?: boolean) {
  if (newState === true || newState === false) {
    featureTogglesData[feature] = newState
  } else {
    featureTogglesData[feature] = !featureTogglesData[feature]
  }
  persistFeatureToggles(featureTogglesData)
}

export default featureTogglesData
