import React, { useEffect, useState } from 'react'
import collect, { Collection } from 'collect.js'
import { verticals as verticalsAPI } from '../services'
import { LocalizedString } from '../i18n'

export enum VerticalSlug {
  FURNITURE = 'furniture',
  ELECTRONICS = 'electronics',
  ELECTRICAL_APPLIANCES = 'electrical-appliances',
  INSURANCE = 'insurance',
  CELLPHONE_SUBSCRIPTIONS = 'cellphone-subscriptions',
  PLANTS = 'plants',
  SERVICES = 'services',
  CONSUMER_PRODUCTS = 'consumer-products',
  SOFTWARE_AND_LICENSES = 'software-and-licenses',
}

export const verticalSlugValueToEnum = (value: string, fallback: VerticalSlug): VerticalSlug => {
  for (const prop in VerticalSlug) {
    if (VerticalSlug[prop] === value) {
      return VerticalSlug[prop]
    }
  }
  return fallback
}

export type Category = {
  id: number
  name: LocalizedString
  slug: string
  subcategories: Category[]
}

export type Vertical = {
  id: number
  name: LocalizedString
  slug: VerticalSlug
  categories: Category[]
  created_at: string
  updated_at: string
}

export type SubcategoryOption = { label: string; value: number }

export type ContextProps = {
  verticals: Collection<Vertical>
}

export type VerticalWithPeriod = Vertical & { periods: { min: number; max: number } }

export const getVerticalsWithRentalPeriod = (
  verticals: Collection<Vertical>,
): Collection<VerticalWithPeriod> => {
  return verticals.map((vertical) => {
    switch (vertical.slug) {
      case VerticalSlug.FURNITURE:
      case VerticalSlug.ELECTRICAL_APPLIANCES:
      case VerticalSlug.PLANTS:
        return {
          ...vertical,
          periods: {
            min: 6,
            max: 60,
          },
        }
      case VerticalSlug.ELECTRONICS:
        return {
          ...vertical,
          periods: {
            min: 6,
            max: 36,
          },
        }
      case VerticalSlug.SERVICES:
        return {
          ...vertical,
          periods: {
            min: 1,
            max: 60,
          },
        }
      case VerticalSlug.CONSUMER_PRODUCTS:
      case VerticalSlug.SOFTWARE_AND_LICENSES:
      case VerticalSlug.CELLPHONE_SUBSCRIPTIONS:
        return {
          ...vertical,
          periods: {
            min: 1,
            max: 36,
          },
        }
      // verticals with no rental period
      default:
        return {
          ...vertical,
          periods: {
            min: 0,
            max: 0,
          },
        }
    }
  })
}

export const VerticalContext = React.createContext<ContextProps>({ verticals: collect({}) })

export function VerticalContextProvider({ children }: { children?: React.ReactNode }) {
  const [verticals, setVerticals] = useState<Collection<Vertical>>(collect({}))
  useEffect(() => {
    verticalsAPI
      .listVerticals()
      .then((res) => {
        setVerticals(res)
      })
      .catch((err) => console.error(err))
  }, [])

  return <VerticalContext.Provider value={{ verticals }}>{children}</VerticalContext.Provider>
}

export default { VerticalContext, VerticalContextProvider }
